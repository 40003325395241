import { LockIcon } from '@chakra-ui/icons'
import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { Mark } from '~/components/Logo'
import { getAccessToken, setAccessToken } from '~/data/accessToken'
import { UserFragment } from '~/graphql/generated/query.types'

import { IFieldError, toFieldErrorMap } from '@common/api/api.types'
import { CustomInput } from '~/components/custom/CustomInput'
import { useRouterStore } from '~/components/hooks/RouterStore'
import { PageTitle } from '~/components/PageTitle'

interface MfaFields {
  code: string
}

const MfaPage = () => {
  const router = useRouter()
  const routerStore = useRouterStore()
  const verificationId = router.query.verification as string
  const channel = router.query.channel as string
  const onSuccess = router.query.success as string | undefined

  const onSubmit = useCallback(
    async (values: MfaFields, { setErrors }: FormikHelpers<MfaFields>) => {
      const resp = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/mfa`, {
        body: JSON.stringify({ code: values.code.trim(), id: verificationId }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const json = (await resp.json()) as {
        user?: UserFragment
        accessToken?: string
        errors?: IFieldError[]
      }
      if (json.errors) {
        setErrors(toFieldErrorMap(json.errors))
      } else if (json.accessToken) {
        setAccessToken(json.accessToken)
        routerStore.clearEverything()
        if (onSuccess) {
          router.replace({ pathname: onSuccess, query: router.query }).catch(e => console.error(e))
        } else {
          router.replace({ pathname: 'overview' }).catch(e => console.error(e))
        }
      }
    },
    [verificationId, routerStore, onSuccess, router]
  )

  if (getAccessToken()) {
    return null
  }

  return (
    <>
      <PageTitle title="Multi-factor authentication" />
      <Flex height="full" width="full" align="center" justify="center">
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="8">
            <Stack spacing="6" alignItems="center">
              <Mark width={20} />
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size="lg">Check your {channel}</Heading>
                <HStack spacing="1" justify="center">
                  <Text color="muted">{`We've ${
                    channel === 'email' ? 'emailed' : 'texted'
                  } you a 6-digit verification code.`}</Text>
                </HStack>
              </Stack>
            </Stack>
            <Formik
              initialValues={{ code: '' }}
              validate={() => {
                const errors: any = {}
                return errors
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Stack spacing="6">
                    <Stack spacing="4">
                      <Field name="code">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.code && form.touched.code}>
                            <FormLabel htmlFor="code">Code</FormLabel>
                            <CustomInput
                              {...field}
                              autoComplete="one-time-code"
                              formatAs="verification"
                              id="code"
                              value={form.values.code}
                              setValue={(newValue: string) => setFieldValue('code', newValue)}
                              leftElement={<LockIcon color="gray.500" />}
                              onReturn={form.handleSubmit}
                            />
                            <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button type="submit" variant="primary" isLoading={isSubmitting}>
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Container>
      </Flex>
    </>
  )
}

export default MfaPage
