import { createContext } from 'react'

export type CommandBarContextType = {
  openCopilot: () => void
}

export const CommandBarContext = createContext<CommandBarContextType>({
  openCopilot: () => {
    throw new Error('CommandBarContext not initialized')
  },
})
CommandBarContext.displayName = 'CommandBarContext'
