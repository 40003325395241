import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { Mark } from '~/components/Logo'
import { getAccessToken } from '~/data/accessToken'

import { IFieldError, toFieldErrorMap } from '@common/api/api.types'
import { PageTitle } from '~/components/PageTitle'
import { isValidEmail } from '~/util/validation'

interface StartFields {
  email: string
}

const StartPage = () => {
  const router = useRouter()

  const onSubmit = useCallback(
    async (values: StartFields, { setErrors }: FormikHelpers<StartFields>) => {
      const resp = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/start`, {
        body: JSON.stringify({ ...values }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const json = (await resp.json()) as {
        verificationId?: string
        email?: string
        errors?: IFieldError[]
      }
      if (json.errors) {
        setErrors(toFieldErrorMap(json.errors))
      } else if (json.verificationId && json.email) {
        await router.push({
          pathname: '/verify',
          query: { ...router.query, verification: json.verificationId, email: json.email },
        })
      }
    },
    [router]
  )

  useEffect(() => {
    if (getAccessToken()) {
      router.replace({ pathname: 'overview' }).catch(e => console.error(e))
    }
  }, [router])

  if (getAccessToken()) {
    return null
  }

  return (
    <>
      <PageTitle title="Start" />
      <Flex height="full" width="full" align="center" justify="center">
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="4">
            <Stack spacing="6" alignItems="center">
              <Mark width={20} />
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size="lg">First, enter your email</Heading>
                <HStack spacing="1" justify="center">
                  <Text color="muted">We suggest using the email you use at work.</Text>
                </HStack>
              </Stack>
            </Stack>
            <Formik
              initialValues={{ email: '' }}
              validate={(values: { email: string }) => {
                const errors: any = {}
                if (!isValidEmail(values.email)) {
                  errors.email = 'Invalid email address'
                }
                return errors
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Stack spacing="6">
                    <Stack spacing="4" pt="4">
                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.email && form.touched.email}>
                            <Input {...field} id="email" type="email" placeholder=" " data-peer />
                            <FormLabel htmlFor="email" variant="floating">
                              Email
                            </FormLabel>
                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button type="submit" variant="primary" isLoading={isSubmitting}>
                        Continue
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Container>
      </Flex>
    </>
  )
}

export default StartPage
