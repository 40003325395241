import { Alert, Button, Center, Switch, Text, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { BaseSyntheticEvent, PropsWithChildren, useEffect } from 'react'
import { setAccessToken } from '~/data/accessToken'
import { apolloClient } from '~/data/apollo'

import { useRouterStore } from '~/components/hooks/RouterStore'
import { LocalStorageKey, useLocalStorage } from '~/components/hooks/useLocalStorage'
import { getWriteMode, setWriteMode } from '~/data/adminWriteMode'
import { AdminContext } from '~/util/AdminContext'
import { isDev } from '~/util/env'
import { AdminRoute, generateAdminRoute, makeURLStringFromURLObject } from '~/util/route'

export const AdminWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter()
  const routerStore = useRouterStore()
  const [adminDataRaw] = useLocalStorage(LocalStorageKey.ADMIN_DATA)
  const adminData = JSON.parse(adminDataRaw ?? '{}')
  const adminUser = adminData?.adminUser
  const impersonatedUser = adminData?.impersonatedUser
  const showAdminContainer = adminData?.impersonatedUser?.name !== undefined
  const [writeEnabledRaw, setWriteEnabled] = useLocalStorage(LocalStorageKey.ADMIN_WRITE_MODE)
  // Initial load of this value is null (typeof 'object') => should be render `false`
  // Page refresh reads raw value from LS (typeof 'string') => should do string compare to determine value
  // Intrapage toggles / reads (typeof 'boolean') => should use the boolean value
  // This line captures the `boolean|object` as a compare with true:boolean
  // And captures `string` as a compare with `true`:string
  const writeEnabled =
    typeof writeEnabledRaw === 'string' ? writeEnabledRaw === `true` : writeEnabledRaw === true

  useEffect(() => {
    if (writeEnabled !== getWriteMode()) setWriteMode(writeEnabled)
  }, [writeEnabled])

  const goBackToAdmin = () => {
    fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/refresh_token`, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userSid: adminUser?.sid,
      }),
    })
      .then(response => response.json())
      .then(data => {
        const accessToken = data.accessToken
        if (accessToken) {
          setAccessToken(accessToken)
          localStorage?.removeItem(LocalStorageKey.ADMIN_DATA)
          localStorage?.removeItem(LocalStorageKey.ADMIN_WRITE_MODE)
          setTimeout(() => {
            location.reload()
          }, 2000)
          router
            .push(
              makeURLStringFromURLObject(
                generateAdminRoute(AdminRoute.users, { firmSid: impersonatedUser.firm })
              )
            )
            .catch(e => console.error(e))
        }
      })
      .catch(() => alert('error'))
  }

  const onLogOut = () => {
    void fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/logout`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
      .then(() => {
        setAccessToken('')
        localStorage?.removeItem(LocalStorageKey.ADMIN_DATA)
        localStorage?.removeItem(LocalStorageKey.ADMIN_WRITE_MODE)
        void apolloClient.cache.reset({ discardWatches: true })
        routerStore.clearEverything()
        router.push({ pathname: 'start' }).catch(e => console.error(e))
      })
      .catch(() => alert('error'))
  }

  const onChangeWriteMode = (e: BaseSyntheticEvent) => {
    setWriteMode(e?.target?.checked)
    setWriteEnabled(e?.target?.checked)
  }

  return (
    <AdminContext.Provider value={{}}>
      <VStack width="full" spacing="0">
        {children}
        {showAdminContainer && (
          <Alert
            status={isDev ? 'success' : writeEnabled ? 'error' : 'warning'}
            variant="solid"
            style={{ width: '100%', height: 'auto', opacity: '1' }}
          >
            <Center w={'100%'} h="18px">
              <Text fontSize="xs" marginRight={3}>
                <b>Write mode</b>
                <Switch
                  defaultChecked={writeEnabled}
                  id="write_mode"
                  marginLeft={1}
                  size={'sm'}
                  onChange={onChangeWriteMode}
                />
              </Text>
              <Text fontSize="sm" marginRight={3}>
                Impersonating <b>{impersonatedUser?.name}</b> @{' '}
                <b>{impersonatedUser?.firmName ?? impersonatedUser?.firm}</b>
              </Text>
              <Button
                colorScheme="primary"
                variant={'outline'}
                size="xs"
                marginRight={2}
                onClick={goBackToAdmin}
              >
                {adminUser?.name}
              </Button>
              <Button colorScheme="primary" variant={'outline'} size="xs" onClick={onLogOut}>
                Log out
              </Button>
            </Center>
          </Alert>
        )}
      </VStack>
    </AdminContext.Provider>
  )
}
