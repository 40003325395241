import { Button, Container, Flex, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { Mark } from '~/components/Logo'
import { getAccessToken } from '~/data/accessToken'

import { AppLink } from '~/components/AppLink'
import { PageTitle } from '~/components/PageTitle'

interface WaitlistFields {
  code: string
}

const WaitlistPage = () => {
  if (getAccessToken()) {
    return null
  }

  return (
    <>
      <PageTitle title="Waitlist" />
      <Flex height="full" width="full" align="center" justify="center">
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="4">
            <Stack spacing="6" alignItems="center">
              <Mark width={20} />
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size="lg">You're on the waitlist</Heading>
                <HStack spacing="1" justify="center">
                  <Text color="muted">{`We'll send you an email as soon as we're ready for you to start using Rentana.`}</Text>
                </HStack>
              </Stack>
            </Stack>
            <AppLink href="https://rentana.io/">
              <Button width="full" variant="primary">
                Go to homepage
              </Button>
            </AppLink>
          </Stack>
        </Container>
      </Flex>
    </>
  )
}

export default WaitlistPage
