import { PropsWithChildren, useCallback, useContext } from 'react'
import { useFetchFirm, useFetchUserFeatureFlags } from '~/graphql/generated/query.types'

import { FirmContext } from '~/util/FirmContext'
import { UserContext } from '~/util/UserContext'

import { usePusherSubscribe } from './hooks/usePusherSubscribe'

export function FirmWrapper({ children }: PropsWithChildren<unknown>) {
  const { loading, data, refetch } = useFetchFirm()
  const { loading: loadingFFs } = useFetchUserFeatureFlags()
  const user = useContext(UserContext)
  const firm = data?.firm ?? null

  const onPoke = useCallback(() => {
    refetch().catch(e => console.error(e))
  }, [refetch])
  usePusherSubscribe('FIRM', user.firmSid, onPoke)

  if (loading || loadingFFs || !firm) {
    return null
  }

  return (
    <FirmContext.Provider
      value={[
        {
          ...firm,
        },
        refetch,
      ]}
    >
      {children}
    </FirmContext.Provider>
  )
}
