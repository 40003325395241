import { SQFT_IN_ACRE } from './constants'
import { USER_OR_DEAL_MENTION_CAPTURING } from './regex'
import { sortAscending } from './sort'
import { CONTENT_TYPE_LOOKUP } from './web'

export function leftPad(str: string, length: number, pad = '0') {
  const buf: string[] = []
  while (str.length + buf.length < length) {
    buf.push(pad)
  }
  return buf.join('') + str
}

export function rightPad(str: string, length: number, pad = '0') {
  const buf: string[] = []
  while (str.length + buf.length < length) {
    buf.push(pad)
  }
  return str + buf.join('')
}

// https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
export function camelCase(str: string) {
  return alphaNumOnly(str).replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
  // return str
  //   .replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2, offset) {
  //     if (p2) {
  //       return p2.toUpperCase()
  //     }
  //     return p1.toLowerCase()
  //   })
  //   .replace(/[\s-_]/g, '')
}

// convert all non-alphanumeric characters to empty string
export function alphaNumOnly(str: string) {
  return str.replace(/[^a-zA-Z0-9]/g, '')
}

export function formatAddr(addr: string): string {
  if (addr.includes('@')) {
    // it's an email, return as is (later normalize)
    return addr
  }
  const formatted = formatPhoneNumber(addr)
  if (formatted) {
    return formatted
  }
  return addr
}

/**
 * Truncates a thing!
 */
export function truncate(str: string, maxLen?: number) {
  const len = maxLen ?? 100
  if (str.length <= len) {
    return str
  }
  return str.substr(0, len) + '…'
}

/**
 * Truncates a thing!
 */
export function trimPrefix(str: string, ...prefixes: string[]) {
  let ret = str
  for (const prefix of prefixes) {
    if (ret.startsWith(prefix)) {
      ret = ret.substring(prefix.length)
    }
  }
  return ret
}

export function formatAddress(
  addr:
    | {
        street?: string | null
        unit?: string | null
        city?: string | null
        state?: string | null
        zipcode?: string | null
      }
    | null
    | undefined,
  ignoreStreetAndUnit = false,
  ignoreCityStateZip = false
): string {
  if (!addr) {
    return ''
  }
  let ret = ''
  if (!ignoreStreetAndUnit) {
    if (addr.street) {
      ret += addr.street + ' '
    }
    if (addr.unit) {
      ret += '#' + addr.unit + ' '
    }
  }
  if (!ignoreCityStateZip) {
    if (addr.city) {
      if (ret) {
        ret = ret.trim() + ', '
      }
      ret += addr.city + ' '
    }
    if (addr.state) {
      ret += addr.state + ' '
    }
    if (addr.zipcode) {
      ret += addr.zipcode + ' '
    }
  }
  return ret.trim()
}

export function formatContentType(contentType: string) {
  return (CONTENT_TYPE_LOOKUP[contentType] ?? contentType).replace('.', '').toUpperCase()
}

/**
 * Takes a string like "hello @[John Rotfels](id)" and returns "hello John Rothfels"
 */
export function interpolateMentions(str: string) {
  let res = ''
  let lastMatch = 0
  let hasMatch = false
  let match: RegExpExecArray | null = null
  while ((match = USER_OR_DEAL_MENTION_CAPTURING.exec(str))) {
    hasMatch = true
    res = res + str.substring(lastMatch, match.index)
    res = res + match[1]
    lastMatch = match.index + (match[0]?.length ?? 0)
  }
  if (!hasMatch) {
    return str // nothing to interpolate
  }
  return res
}

// https://stackoverflow.com/a/18650828
export function formatBytes(bytes: number, decimals = 0) {
  if (bytes === 0) {
    return '0 bytes'
  }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

export function formatCurrency(
  currency: number,
  precision = 0,
  hideDollarSign?: boolean,
  includePlus?: boolean
) {
  let result = currency.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: precision,
    minimumFractionDigits: precision ?? 0,
  })
  if (hideDollarSign) {
    result = result.replace('$', '')
  }
  if (includePlus && currency > 0) {
    result = '+' + result
  }
  return result
}

export function formatLotSqft(lotSqft: number, precision?: number) {
  const measurement = lotSqft > 10000 ? 'acres' : 'sqft'
  const lotSize = +(measurement === 'acres' ? lotSqft / SQFT_IN_ACRE : lotSqft).toFixed(
    precision ?? 4
  )
  return `${formatNumberWithCommas(lotSize)} ${measurement}`
}

// https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
// the function above doesn't work for react native
export function abbreviateNumber(n: number, precision?: number): string {
  const p = precision ?? 1
  if (n < 1e3) {
    return n.toString()
  } else if (n < 1e6) {
    return +(n / 1e3).toFixed(p) + 'K'
  } else if (n < 1e9) {
    return +(n / 1e6).toFixed(p) + 'M'
  } else if (n < 1e12) {
    return +(n / 1e9).toFixed(p) + 'B'
  }
  return +(n / 1e12).toFixed(p) + 'T'
}

export function formatNumberWithCommas(num: number) {
  return num.toLocaleString('en-US')
}

export function formatPercent(decimal: number, precision?: number, includePlus?: boolean): string {
  const percent = decimal.toLocaleString('en-US', {
    style: 'percent',
    maximumFractionDigits: precision ?? 2,
  })
  if (includePlus && decimal > 0) {
    return '+' + percent
  }
  return percent
}

// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export function formatPhoneNumber(phoneNumber: string | null | undefined): string | undefined {
  if (!phoneNumber) {
    return undefined
  }
  const extIdx = phoneNumber.lastIndexOf(',')
  let extension = ''
  if (extIdx !== -1) {
    extension = phoneNumber.substring(extIdx + 1)
    phoneNumber = phoneNumber.substring(0, extIdx)
  }

  const cleaned = phoneNumber.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    // don't display international code for now
    const intlCode = '' // match[1] ? '+1 ' : ''
    return [
      intlCode,
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
      extension ? ` ext. ${extension}` : '',
    ].join('')
  }
  return undefined
}

export function formattedPhoneNumberToRaw(formattedPhoneNumberString: string | null | undefined) {
  if (!formattedPhoneNumberString) {
    return undefined
  }
  const extIdx = formattedPhoneNumberString.lastIndexOf('ext. ')
  let extension = ''
  if (extIdx !== -1) {
    extension = formattedPhoneNumberString.substring(extIdx + 5)
    formattedPhoneNumberString = formattedPhoneNumberString.substring(0, extIdx)
  }
  const cleaned = stripToNumber(formattedPhoneNumberString)
  if (cleaned.length !== 10) {
    return undefined
  }
  return `+1${cleaned}${extension ? `,${extension}` : ''}`
}

export function capitalize(str: string, restLowercase = true) {
  if (!str) {
    return str
  }
  const rest = restLowercase ? str.slice(1).toLowerCase() : str.slice(1)
  return str[0]!.toUpperCase() + rest
}

export function uncapitalize(str: string) {
  if (!str.length) {
    return str
  }
  const rest = str.substr(1)
  return str[0]?.toLowerCase() + rest
}

export function plural(str: string, n = 1) {
  if (n === 1) {
    return `${n} ${str}`
  }
  const formattedNumber = formatNumberWithCommas(n)
  if (str.endsWith('y') && !str.endsWith('ay')) {
    return `${formattedNumber} ${str.substring(0, str.length - 1)}ies`
  }
  return `${formattedNumber} ${str}s`
}

export function isEmpty(str: string): boolean {
  return !str || str.trim() === ''
}

export function stringIfNotEmpty(str: string): string | undefined {
  return isEmpty(str) ? undefined : str.trim()
}

export function stripToNumber(str: string, includeDecimal?: boolean): string {
  const expression = includeDecimal ? /-?[^0-9.]/g : /-?\D/g
  return str.replace(expression, '')
}

export function englishList(items: string[]) {
  if (items.length === 0) {
    return ''
  }
  if (items.length === 1) {
    return items[0]
  }
  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`
  }
  return `${items.slice(0, items.length - 1).join(', ')}, and ${items[items.length - 1]}`
}

export function bedroomCountLabel(bedrooms: number | null | undefined) {
  return `${bedrooms ?? '?'}bd`
}

/**
 * e.g. if input is [1, 2, 3, 5, 7, 8, 9], this returns "1-3, 5, 7-9"
 */
export function formatIntegerArrayAsContiguousIntervals(integers: number[]): string {
  if (integers.length === 0) {
    return ''
  }

  const sortedIntegers = sortAscending(integers, (integer: number) => integer)
  const intervals: [number, number][] = []
  let intervalStart = sortedIntegers[0]!
  let intervalEnd = sortedIntegers[0]!
  for (let i = 1; i < sortedIntegers.length; i++) {
    const current = sortedIntegers[i]!
    if (sortedIntegers[i] === intervalEnd + 1) {
      intervalEnd = current
    } else {
      intervals.push([intervalStart, intervalEnd])
      intervalStart = current
      intervalEnd = current
    }
  }

  // Add the last interval to the intervals
  intervals.push([intervalStart, intervalEnd])

  const formattedIntervals = intervals.map(interval => {
    if (interval[0] === interval[1]) {
      return `${interval[0]}`
    }
    return `${interval[0]}-${interval[1]}`
  })
  return formattedIntervals.join(', ')
}

export function formatRange(
  min: number,
  max: number,
  formatter: (value: number) => string,
  useEndash?: boolean
) {
  const isSame = min === max

  // endashes are nice for displaying ranges in UIs but in a CSV or other export they
  // may not be formatted correctly. For those other uses we should display a relative
  // dash instead.
  return isSame ? formatter(min) : `${formatter(min)}${useEndash ? '–' : '-'}${formatter(max)}`
}
