import { stripToNumber } from '@common/util/format'

export function getChangedValues<Values extends Record<string, unknown>>(
  values: Values,
  initialValues: Values
) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const initialValue = initialValues[key]
    const compareValue = isNumber(value) ? String(value) : value
    const allNothing = [undefined, null, '', 0]
    if (allNothing.includes(initialValue as any) && allNothing.includes(compareValue as any)) {
      return acc
    }
    const hasChanged = initialValue != compareValue
    if (hasChanged) {
      acc[key as keyof typeof acc] = value as any
    }
    return acc
  }, {} as Partial<Values>)
}

export function isValidDomain(domain: string): boolean {
  return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(domain)
}

export function isValidEmail(email: string): boolean {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

export function isValidJson(jsonString: string): boolean {
  try {
    const jsonObject = JSON.parse(jsonString)
    return true
  } catch {
    return false
  }
}

export function isValidPhone(phone: string): boolean {
  phone = phone.replace('+1', '')
  const structuredPhone = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phone)
  if (structuredPhone) {
    return true
  }
  return stripToNumber(phone).length === 10
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export function isValidUrl(url: string | undefined): boolean {
  if (!url) {
    return false
  }
  if (!url.includes('http://') && !url.includes('https://')) {
    return false
  }
  try {
    new URL(url)
  } catch (e) {
    return false
  }
  return true
}
