import { ApolloQueryResult } from '@apollo/client'
import React from 'react'
import { FetchFirm, FirmFragment } from '~/graphql/generated/query.types'

// For convenience, we use a non-nullable type since most of the application requires an authenticated user.
// When the user context has an undefined user, the application will redirect to login and force authentication
// the rest of the application can render / receive a valid UserContext.
export const FirmContext = React.createContext<
  [FirmFragment, () => Promise<ApolloQueryResult<FetchFirm>>]
>(undefined as any)
// https://reactjs.org/docs/context.html#contextdisplayname
FirmContext.displayName = 'FirmContext'
