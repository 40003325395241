import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

const baseStyle = (props: StyleFunctionProps) => ({
  track: {
    bg: mode('gray.300', 'gray.700')(props),
    _checked: {
      bg: 'brand.500',
    },
  },
})

export default {
  baseStyle,
}
