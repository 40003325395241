export const ONLY_DIGITS = /^\d+$/

// regex for only digits and comma
export const ONLY_DIGITS_AND_COMMA = /^[\d,]+$/

// https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const urlRegex =
  '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'

export const URL_REGEX = new RegExp(urlRegex, 'i')

/**
 * `"@[John Rothfels](john) and @[Elliott Burris](elliott) are awesome!"` contains two matches:
 * - `@[John Rothfels](john)`
 * - `@[Elliott Burris](elliott)`
 */
export const USER_MENTION = /@\[.+?\]\(\S+\)/g
export const DEAL_MENTION = /#\[.+?\]\(\S+\)/g
export const USER_OR_DEAL_MENTION = /[#@]\[.+?\]\(\S+\)/g
export const USER_MENTION_CAPTURING = /@\[(.+?)\]\((\S+)\)/g
export const DEAL_MENTION_CAPTURING = /#\[(.+?)\]\((\S+)\)/g
export const USER_OR_DEAL_MENTION_CAPTURING = /[#@]\[(.+?)\]\((\S+)\)/g
