import { useEffect } from 'react'

export function useSmartPolling(
  pollIntervalMillis: number,
  startPolling: (pollIntervalMillis: number) => void,
  stopPolling: () => void
) {
  useEffect(() => {
    startPolling(pollIntervalMillis)
  }, [pollIntervalMillis, startPolling])

  useEffect(() => {
    const handleVisibilityChange = (e: any) => {
      if (document.visibilityState === 'visible') {
        startPolling(pollIntervalMillis)
      } else {
        stopPolling()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [pollIntervalMillis, startPolling, stopPolling])
}
