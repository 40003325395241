import '@fontsource-variable/inter'
import '../components/App.css'

import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { browserTracingIntegration } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { extend } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { AppProps } from 'next/app'
import { apolloClient, ToastComponent } from '~/data/apollo'

import { AdminWrapper } from '~/components/AdminWrapper'
import { AuthWrapper } from '~/components/AuthWrapper'
import { CommandBarWrapper } from '~/components/CommandBarWrapper'
import { FirmWrapper } from '~/components/FirmWrapper'
import { theme } from '~/theme/theme'
import { Z_INDEX_PORTAL } from '~/theme/zIndex'
import 'react-day-picker/dist/style.css'

extend(isBetween)

Sentry.init({
  dsn: 'https://66ee286b107c4231b96275d756c12531@o4504469882470400.ingest.sentry.io/4504469888958464',
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 1.0,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
})

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider resetCSS theme={theme} cssVarsRoot="body" portalZIndex={Z_INDEX_PORTAL}>
      <ApolloProvider client={apolloClient}>
        <AuthWrapper>
          <FirmWrapper>
            <AdminWrapper>
              <CommandBarWrapper>
                <Component {...pageProps} />
                <ToastComponent />
              </CommandBarWrapper>
            </AdminWrapper>
          </FirmWrapper>
        </AuthWrapper>
      </ApolloProvider>
    </ChakraProvider>
  )
}

export default MyApp
