import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { Mark } from '~/components/Logo'
import { getAccessToken, setAccessToken } from '~/data/accessToken'

import { IFieldError, toFieldErrorMap } from '@common/api/api.types'
import { PageTitle } from '~/components/PageTitle'

interface SignupFields {
  fullName: string
}

export const SignupPage = () => {
  const router = useRouter()

  const waitlistId = router.query.waitlist as string
  const email = router.query.email as string

  const onSuccess = router.query.success as string | undefined

  const onSubmit = useCallback(
    async (values: SignupFields, { setErrors }: FormikHelpers<SignupFields>) => {
      const payload = {
        ...values,
        waitlistId,
        email,
      }
      const resp = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/signup`, {
        body: JSON.stringify(payload),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const json = (await resp.json()) as { accessToken?: string; errors?: IFieldError[] }
      if (json.errors) {
        setErrors(toFieldErrorMap(json.errors))
      } else if (json.accessToken) {
        setAccessToken(json.accessToken)
        if (onSuccess) {
          router
            .push({ pathname: onSuccess, query: { ...router.query } })
            .catch(e => console.error(e))
        } else {
          router.push({ pathname: 'overview' }).catch(e => console.error(e))
        }
      }
    },
    [waitlistId, email, onSuccess, router]
  )

  useEffect(() => {
    if (getAccessToken()) {
      router.replace({ pathname: 'overview' }).catch(e => console.error(e))
    }
  }, [router])

  if (getAccessToken()) {
    return null
  }

  return (
    <>
      <PageTitle title={'Sign up'} />
      <Flex height="full" width="full" align="center" justify="center">
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="4">
            <Stack spacing="6" align="center">
              <Mark width={20} />
              <Stack spacing="3" textAlign="center">
                <Heading size="lg">Create an account</Heading>
                <Text color="muted">Get started on the Rentana platform.</Text>
              </Stack>
            </Stack>
            <Formik
              initialValues={{ fullName: '' }}
              validate={(values: { fullName: string }) => {
                const errors: any = {}
                if (values.fullName.length < 2) {
                  errors.fullName = 'Invalid first name'
                }

                return errors
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Stack spacing="6">
                    <Stack spacing="5">
                      <Field name="fullName">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.fullName && form.touched.fullName}>
                            <FormLabel htmlFor="first">Name</FormLabel>
                            <Input {...field} id="first" />
                            <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <Stack spacing="4">
                      <Button type="submit" variant="primary" isLoading={isSubmitting}>
                        Create account
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Container>
      </Flex>
    </>
  )
}

export default SignupPage
