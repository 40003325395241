import { ParsedUrlQuery } from 'querystring'

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface RouterStoreState {
  lastPath: string | null
  setLastPath: (path: string) => void
  queryForPath: Record<string, ParsedUrlQuery>
  getQueryForPath: (path: string) => ParsedUrlQuery
  setQueryForPath: (path: string, query: ParsedUrlQuery) => void
  clearEverything: () => void
  validPaths: string[]
}

export const useRouterStore = create(
  persist<RouterStoreState>(
    (set, get) => ({
      lastPath: null,
      setLastPath: (lastPath: string) => {
        if (get().validPaths.includes(lastPath)) {
          set({ lastPath })
        }
      },
      queryForPath: {},
      getQueryForPath: (path: string) => get().queryForPath[path.replace('/', '')] ?? {},
      setQueryForPath: (path: string, newQuery: ParsedUrlQuery) => {
        set({ queryForPath: { ...get().queryForPath, [path.replace('/', '')]: newQuery } })
      },
      clearEverything: () => {
        set({ lastPath: null, queryForPath: {} })
      },
      validPaths: [
        'overview',
        'properties',
        'market',
        'admin',
        'timeseries',
        'alerts',
        'firm',
        'database',
        'history',
      ],
    }),
    {
      name: 'router-storage', // unique name
    }
  )
)
