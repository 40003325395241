import { LockIcon } from '@chakra-ui/icons'
import {
  Button,
  chakra,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { Mark } from '~/components/Logo'
import { getAccessToken, setAccessToken } from '~/data/accessToken'

import { IFieldError, toFieldErrorMap } from '@common/api/api.types'
import { CustomInput } from '~/components/custom/CustomInput'
import { PageTitle } from '~/components/PageTitle'

interface VerifyFields {
  code: string
}

const VerifyPage = () => {
  const router = useRouter()
  const email = router.query.email as string
  const verificationId = router.query.verification as string
  const onSuccess = router.query.success as string | undefined

  const onSubmit = useCallback(
    async (values: VerifyFields, { setErrors, setSubmitting }: FormikHelpers<VerifyFields>) => {
      const resp = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/verify`, {
        body: JSON.stringify({ code: values.code.trim(), verificationId }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const json = (await resp.json()) as {
        email?: string
        waitlist?: string
        approved?: boolean
        accessToken?: string
        errors?: IFieldError[]
      }
      if (json.errors) {
        setErrors(toFieldErrorMap(json.errors))
        setSubmitting(false)
      } else if (json.accessToken) {
        setAccessToken(json.accessToken)
        if (onSuccess) {
          router.push({ pathname: onSuccess, query: router.query }).catch(e => console.error(e))
        } else {
          router.push({ pathname: 'overview' }).catch(e => console.error(e))
        }
      } else if (json.approved && json.waitlist && json.email) {
        router
          .push({
            pathname: 'signup',
            query: { ...router.query, waitlist: json.waitlist, email: json.email },
          })
          .catch(e => console.error(e))
      } else {
        router.push({ pathname: 'waitlist' }).catch(e => console.error(e))
      }
    },
    [verificationId, router, onSuccess]
  )

  if (getAccessToken()) {
    return null
  }

  return (
    <>
      <PageTitle title="Verify email" />
      <Flex height="full" width="full" align="center" justify="center">
        <Container maxW="md" py={{ base: '12', md: '24' }}>
          <Stack spacing="4">
            <Stack spacing="6" alignItems="center">
              <Mark width={20} />
              <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                <Heading size="lg">Check your email</Heading>
                <HStack spacing="1" justify="center">
                  <Text color="muted">
                    We’ve sent a 6-character code to{' '}
                    <chakra.span fontWeight="bold">{email}</chakra.span>. The code expires shortly,
                    so please enter it soon.
                  </Text>
                </HStack>
              </Stack>
            </Stack>
            <Formik
              initialValues={{ code: '' }}
              validate={(values: { code: string }) => {
                const errors: any = {}
                return errors
              }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>
                  <Stack spacing="6">
                    <Stack spacing="4">
                      <Field name="code">
                        {({ field, form }: any) => (
                          <FormControl isInvalid={form.errors.code && form.touched.code}>
                            <FormLabel htmlFor="code">Code</FormLabel>
                            <CustomInput
                              {...field}
                              autoComplete="one-time-code"
                              formatAs="verification"
                              id="code"
                              value={form.values.code}
                              setValue={(newValue: string) => setFieldValue('code', newValue)}
                              leftElement={<LockIcon color="gray.500" />}
                              onReturn={form.handleSubmit}
                            />
                            <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                      <Button type="submit" variant="primary" isLoading={isSubmitting}>
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
            <HStack justify="center">
              <Text fontSize="sm" color="muted">
                Having trouble? Check your spam folder!
              </Text>
            </HStack>
          </Stack>
        </Container>
      </Flex>
    </>
  )
}

export default VerifyPage
