import { init } from 'commandbar'
import { useRouter } from 'next/router'
import { PropsWithChildren, useContext, useEffect, useState } from 'react'

import { isNotNullOrUndefined } from '@common/util/isNotNullOrUndefined'
import { daysBeforeNow } from '@common/util/time'
import { useCurrentUser } from '~/components/hooks/useCurrentUser'
import { LocalStorageKey } from '~/components/hooks/useLocalStorage'
import { CommandBarContext } from '~/util/CommandBarContext'
import { FirmContext } from '~/util/FirmContext'

if (typeof window !== 'undefined') {
  init('2e70e5ea')
}

const windowAny = typeof window !== 'undefined' ? window : ({} as any)
const adminData = windowAny?.localStorage?.getItem(LocalStorageKey.ADMIN_DATA)
const isImpersonating = isNotNullOrUndefined(adminData)

export function CommandBarWrapper({ children }: PropsWithChildren) {
  const user = useCurrentUser()
  const [firm] = useContext(FirmContext)
  const router = useRouter()

  const [openCopilot, setOpenCopilot] = useState(() => () => {})

  useEffect(
    function initCommandBar() {
      if (!isImpersonating) {
        window.CommandBar.boot(user.sid, {
          name: user.fullName,
          email: user.email,
          role: user.role,
          firmSid: user.firmSid,
          firmName: firm.name,
          daysOld: daysBeforeNow(user.timeCreated),
        }).catch((e: any) => console.error(e))
        const routerFunc = (path: string) => {
          void router.push(path)
        }
        window.CommandBar.addRouter(routerFunc)
        setOpenCopilot(() => () => window.CommandBar.openCopilot())
      }

      return () => {
        if (!isImpersonating) {
          window.CommandBar.shutdown()
        }
      }
    },
    [firm, router, user]
  )

  useEffect(
    function setColorMode() {
      if (!isImpersonating) {
        window.CommandBar.setThemeMode(user.darkMode ? 'dark_mode' : 'light_mode')
      }
    },
    [user.darkMode]
  )

  return <CommandBarContext.Provider value={{ openCopilot }}>{children}</CommandBarContext.Provider>
}
