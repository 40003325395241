const baseStyle = () => ({
  control: {
    _checked: {
      bg: 'brand.500',
      borderColor: 'brand.500',
      _hover: {
        bg: 'brand.500',
        borderColor: 'brand.500',
      },
    },
  },
})

export default {
  baseStyle,
}
