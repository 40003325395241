import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

const baseStyle = (props: StyleFunctionProps) => ({
  dialog: {
    bg: mode('white', 'gray.800')(props),
    borderRadius: 'md',
  },
})

const sizes = {
  ['7xl']: {
    dialog: {
      maxW: '80rem',
    },
  },
  ['8xl']: {
    dialog: {
      maxW: '90rem',
    },
  },
}
export default {
  baseStyle,
  sizes,
}
