import { Flex, useColorMode } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import { getAccessToken, setAccessToken } from '~/data/accessToken'
import { useFetchMe, UserFragment } from '~/graphql/generated/query.types'

import { useSmartPolling } from '~/components/hooks/polling'
import MfaPage from '~/pages/mfa'
import SignupPage from '~/pages/signup'
import StartPage from '~/pages/start'
import VerifyPage from '~/pages/verify'
import WaitlistPage from '~/pages/waitlist'
import { UserContext } from '~/util/UserContext'

import { PageSpinner } from './PageSpinner'

/**
 * Page component keeps track of the current user, and provides it to downstream components via the
 * UserContext. If there is no user, renders the Login page.
 */
export function AuthWrapper({ children }: PropsWithChildren<unknown>) {
  const router = useRouter()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/refresh_token`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(async x => {
        const { accessToken } = await x.json()
        setAccessToken(accessToken)
        setLoading(false)
      })
      .catch(e => console.error(e))
  }, [])
  if (loading) {
    return <PageSpinner />
  }

  if (!loading && !getAccessToken()) {
    return (
      <Flex width="100vw" height="100vh">
        {router.pathname.startsWith('/signup') ? (
          <SignupPage />
        ) : router.pathname.startsWith('/mfa') ? (
          <MfaPage />
        ) : router.pathname.startsWith('/verify') ? (
          <VerifyPage />
        ) : router.pathname.startsWith('/waitlist') ? (
          <WaitlistPage />
        ) : (
          <StartPage />
        )}
      </Flex>
    )
  }

  if (router.route === '/_error') {
    return <>{children}</>
  }

  return (
    <UserContextWrapper>
      <Flex width="100vw" height="100vh">
        {children}
      </Flex>
    </UserContextWrapper>
  )
}

function UserContextWrapper({ children }: PropsWithChildren<unknown>) {
  const [user] = useState(jwtDecode<UserFragment>(getAccessToken()))
  const { data, startPolling, stopPolling } = useFetchMe()
  useSmartPolling(30000, startPolling, stopPolling)

  const { colorMode, toggleColorMode } = useColorMode()
  useEffect(() => {
    if (!data) {
      return
    }
    const desiredColorModeString = data?.me?.darkMode ? 'dark' : 'light'
    if (desiredColorModeString === colorMode) {
      return
    }
    toggleColorMode()
  }, [data, colorMode, toggleColorMode])

  useEffect(() => {
    if (data?.me) {
      Sentry.setUser(data?.me)
    }
  }, [data?.me])

  const userFragment: UserFragment = data?.me || {
    __typename: 'GQLUser',
    ...(user as any),
  }
  return <UserContext.Provider value={userFragment}>{children}</UserContext.Provider>
}
