import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  FieldPolicy,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createStandaloneToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { UserFragment } from '~/graphql/generated/query.types'

import { log } from '@common/log/log'
import { LocalStorageKey } from '~/components/hooks/useLocalStorage'
import { getWriteMode } from '~/data/adminWriteMode'

import { getAccessToken, setAccessToken } from './accessToken'

const { ToastContainer, toast } = createStandaloneToast()

export const ToastComponent = ToastContainer

// for Chrome devtools / local debugging convenience
const windowAny = typeof window !== 'undefined' ? window : ({} as any)
const adminData = windowAny?.localStorage?.getItem(LocalStorageKey.ADMIN_DATA)
const isImpersonating = adminData !== null && adminData !== undefined
if (isImpersonating) log.info('admin[isImpersonating]')

const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_BASE_API_URL}/graphql`,
  credentials: 'include',
  fetch: (uri: any, options: any) => {
    const reqBody = JSON.parse(options.body as string)
    const opName = reqBody.operationName
    return fetch(`${uri}?opName=${opName}`, {
      ...options,
      headers: { authorization: `bearer ${getAccessToken()}`, ...(options.headers || {}) },
    })
  },
})

const adminWriteModeCheck = new ApolloLink((operation, forward) => {
  // If `Fetch` is first part of the operationName, we know it's a GQL `query` and allowable
  if (operation?.operationName?.startsWith('Fetch') || getWriteMode() === true) {
    // Allow operation because it's a GET or WriteMode is enabled.
    return forward(operation)
  }
  // Otherwise, block the operation
  log.info('adminLink[blocked]', operation.operationName)
  toast({
    title: 'Write mode disabled',
    description: `You must enable impersonation write mode to perform the ${operation.operationName} action.`,
    status: 'warning',
    duration: 5000,
    isClosable: true,
  })
  throw new Error('Write mode disabled')
})

function paginatedTypePolicy(
  keyArgs: string[] | undefined = undefined,
  sortOrder = 'asc'
): FieldPolicy<any> | FieldReadFunction<any> {
  return {
    keyArgs: keyArgs ? keyArgs : false,
    // keyFields: [],

    merge(existing, incoming, { readField }) {
      const items = existing ? { ...existing.items } : {}
      const incomingItems = readField('items', incoming) as any[]
      incomingItems.forEach((item: any) => {
        const index = readField('id', item)! || readField('sid', item)!
        items[index.toString()] = item
      })
      return {
        __typename: readField('__typename', incoming),
        cursor: incoming.cursor,
        items,
      }
    },

    read(existing) {
      if (existing) {
        const sortNum = sortOrder === 'asc' ? -1 : 1
        return {
          __typename: existing.__typename,
          cursor: existing.cursor,
          items: Object.values(existing.items).sort((a: any, b: any) =>
            a.timeCreated < b.timeCreated ? sortNum : -sortNum
          ),
        }
      }
    },
  }
}

const cache = new InMemoryCache({
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  dataIdFromObject(responseObject) {
    if (responseObject.id || responseObject.sid) {
      return `${responseObject.__typename}:${responseObject.id || responseObject.sid}`
    }
    return defaultDataIdFromObject(responseObject)
  },
  typePolicies: {
    Query: {
      fields: {
        isTyping: {
          merge(existing, incoming) {
            return incoming
          },
        },
        listings: paginatedTypePolicy(),
        allAssets: paginatedTypePolicy(),
        crawls: paginatedTypePolicy(),
        crawlEvents: paginatedTypePolicy(['crawlId'], 'desc'),
        deals: paginatedTypePolicy(['firmSid']),
        allUsers: paginatedTypePolicy(),
        allBrokerages: paginatedTypePolicy(),
        allBrokers: paginatedTypePolicy(),
        conversations: paginatedTypePolicy(),
        jobs: paginatedTypePolicy([], 'desc'),
        messages: paginatedTypePolicy(['conversationSid']),
        searchAssets: paginatedTypePolicy(['query']),
        searchBrokerages: paginatedTypePolicy(['query']),
        searchListings: paginatedTypePolicy(['query']),
        searchDeals: paginatedTypePolicy(['firmSid', 'query']),
        searchUsers: paginatedTypePolicy(['query']),
        searchBrokers: paginatedTypePolicy(['query', 'brokerageSid']),
      },
    },
  },
})

const links = [
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    // eslint-disable-next-line @typescript-eslint/require-await
    isTokenValidOrUndefined: async () => {
      const token = getAccessToken()

      if (!token) {
        return false
      }

      try {
        const { exp } = jwtDecode<JwtPayload>(token)
        if (Date.now() >= exp! * 1000) {
          return false
        }
        return true
      } catch {
        return false
      }
    },
    fetchAccessToken: () => {
      return fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}/refresh_token`, {
        method: 'POST',
        credentials: 'include',
      })
    },
    handleFetch: accessToken => {
      setAccessToken(accessToken)
    },
    handleError: err => {
      console.warn('Your refresh token is invalid. Try to relogin')
      console.error(err)
    },
  }),
  onError(({ graphQLErrors, networkError }) => {
    const errors: string[] = []
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path }) => {
        const err = `${path}: ${message}`
        console.error(`[GraphQL error]: ${err}`)
        Sentry.captureException(err)
        errors.push(err)
      })
    }
    if (networkError) {
      const err = `${networkError.name} ${networkError.message}`
      if (`${err}`.includes('Received status code 403')) {
        return // don't toast; this is a valid 403 error when the 15min access token expires
      }
      console.error(`[GraphQL network error]: ${err}`)
      errors.push(err)
    }

    if (errors.length > 0) {
      const token = getAccessToken()
      if (!token) {
        return // defensive check, the token should be defined
      }

      const user = jwtDecode<UserFragment>(token)
      if (user?.admin) {
        const msg = errors.join('\n')
        // deduplication on message string, we don't need to show the same error twice
        if (!toast.isActive(msg)) {
          toast({
            id: msg,
            title: 'Error',
            duration: null,
            description: errors.join('\n'),
            status: 'error',
            isClosable: true,
          })
        }
      }
    }
  }),
  // requestLink,
  httpLink,
]

// If we're an admin and impersonating someone, add our `writeMode`
// middleware that will always verify that write is enabled before allowing
// any `non-Fetch` queries.
if (isImpersonating) links.unshift(adminWriteModeCheck)
const link = ApolloLink.from(links)

// consider using https://github.com/adamsoffer/next-apollo
// https://www.rockyourcode.com/nextjs-with-apollo-ssr-cookies-and-typescript/
// https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/
export const apolloClient = new ApolloClient({
  link,
  cache,
  // queryDeduplication: false,
  // defaultOptions: {
  //   query: {
  //     fetchPolicy: 'cache-first',
  //   },
  // },
})

windowAny.apolloCache = cache
windowAny.apolloClient = apolloClient
