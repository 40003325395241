import { stringify } from 'querystring'

import { ReportType, UnitGroup } from '~/graphql/generated/query.types'

import { exhaustiveSwitchError } from '@common/util/exhaustiveSwitchError'

export type WebRoute = {
  pathname: string
  query: { view?: string } & Record<string, string | undefined>
}

export enum OverviewRoute {
  dashboard = 'dashboard',
  metrics = 'metrics',
}

export function generateOverviewRoute(
  route?: OverviewRoute,
  options?: { portfolioSid?: string }
): WebRoute {
  return {
    pathname: 'overview',
    query: { ...options, view: route },
  }
}

export enum PropertiesRoute {
  summary = 'summary',
  pricing = 'pricing',
  pricingHistory = 'pricingHistory',
  units = 'units',
  leases = 'leases',
  comps = 'comps',
  specials = 'specials',
  amenities = 'amenities',
  settings = 'settings',
}

export function generatePropertiesRoute(
  route?: PropertiesRoute,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute,
  options?: { portfolioSid?: string; propertySid?: string; group?: UnitGroup }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute,
  options?: { portfolioSid?: string; propertySid?: string; group?: UnitGroup }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.units,
  options?: { portfolioSid?: string; propertySid?: string; unitSid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.leases,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.comps,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.specials,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.amenities,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute.settings,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generatePropertiesRoute(
  route?: PropertiesRoute,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute {
  return {
    pathname: 'properties',
    query: { ...options, view: route },
  }
}

export enum RenewalsRoute {
  expirations = 'expirations',
  monthToMonth = 'monthToMonth',
  batches = 'batches',
  summary = 'summary',
}

export function generateRenewalsRoute(
  route?: RenewalsRoute.summary,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generateRenewalsRoute(
  route?: RenewalsRoute.expirations,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generateRenewalsRoute(
  route?: RenewalsRoute.monthToMonth,
  options?: { portfolioSid?: string; propertySid?: string }
): WebRoute
export function generateRenewalsRoute(
  route?: RenewalsRoute.batches,
  options?: {
    portfolioSid?: string
    propertySid?: string
    renewalRequestSid?: string
    activeTab?: string
  }
): WebRoute
export function generateRenewalsRoute(
  route?: RenewalsRoute,
  options?:
    | { portfolioSid?: string; propertySid?: string }
    | { portfolioSid?: string; propertySid?: string; renewalRequestSid?: string }
): WebRoute {
  return {
    pathname: 'renewals',
    query: { ...options, view: route },
  }
}

export enum ReportsRoute {
  metrics = 'metrics',
  reports = 'reports',
  scheduled = 'scheduled',
  history = 'history',
  preview = 'preview',
}

export function generateReportsRoute(route?: ReportsRoute.metrics, options?: {}): WebRoute
export function generateReportsRoute(
  route?: ReportsRoute.reports,
  options?: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route?: ReportsRoute.history,
  options?: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route?: ReportsRoute.scheduled,
  options?: { reportType?: undefined; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route?: ReportsRoute.preview,
  options?: { reportType: ReportType; reportSid?: string; sid?: string }
): WebRoute
export function generateReportsRoute(
  route?: ReportsRoute,
  options?: { reportType?: ReportType; reportSid?: string; sid?: string }
): WebRoute {
  return {
    pathname: 'reports',
    query: { ...options, view: route },
  }
}

export enum CompanyRoute {
  information = 'information',
  properties = 'properties',
  portfolios = 'portfolios',
  users = 'users',
  pricing = 'pricing',
  recipientGroup = 'recipient_group',
}
export function generateCompanyRoute(route?: CompanyRoute): WebRoute {
  return {
    pathname: 'company',
    query: { view: route },
  }
}

export enum AdminRoute {
  users = 'users',
  featureFlags = 'featureFlags',
  jobs = 'jobs',
  jobQueues = 'jobQueues',
  alerts = 'alerts',
  comps = 'comps',
  firms = 'firms',
  properties = 'properties',
  metrics = 'metrics',
  models = 'models',
  recommendations = 'recommendations',
  graphql = 'graphql',
}
export function generateAdminRoute(
  route?: AdminRoute,
  options: {
    firmSid?: string
  } = {}
): WebRoute {
  return {
    pathname: 'admin',
    query: { ...options, view: route },
  }
}

export function makeURLStringFromURLObject(
  urlObject: WebRoute,
  options?: { hash?: string }
): string {
  // Filter out properties with null or undefined values
  const filteredQuery = Object.entries(urlObject.query)
    .filter(([_key, value]) => value !== null && value !== undefined)
    .reduce(
      (obj, [key, value]) => {
        obj[key] = value as any
        return obj
      },
      {} as Record<string, string>
    )

  // Stringify the result
  const query = stringify(filteredQuery)
  return urlObject.pathname + (query ? '?' + query : '') + (options?.hash ? '#' + options.hash : '')
}

export function getRouteLabel(
  route: OverviewRoute | PropertiesRoute | RenewalsRoute | ReportsRoute | CompanyRoute | AdminRoute
): string {
  switch (route) {
    case OverviewRoute.dashboard:
      return 'Dashboard'
    case OverviewRoute.metrics:
      return 'Metrics'

    case PropertiesRoute.summary:
      return 'Summary'
    case PropertiesRoute.pricing:
      return 'Pricing'
    case PropertiesRoute.pricingHistory:
      return 'Pricing history'
    case PropertiesRoute.units:
      return 'Units'
    case PropertiesRoute.leases:
      return 'Leases'
    case PropertiesRoute.comps:
      return 'Comps'
    case PropertiesRoute.specials:
      return 'Specials'
    case PropertiesRoute.amenities:
      return 'Amenities'
    case PropertiesRoute.settings:
      return 'Settings'

    case RenewalsRoute.summary:
      return 'Summary'
    case RenewalsRoute.expirations:
      return 'Lease expirations'
    case RenewalsRoute.monthToMonth:
      return 'Month-to-month'
    case RenewalsRoute.batches:
      return 'Batches'

    case ReportsRoute.metrics:
      return 'Metrics'
    case ReportsRoute.reports:
      return 'Reports'
    case ReportsRoute.scheduled:
      return 'Scheduled'
    case ReportsRoute.history:
      return 'History'
    case ReportsRoute.preview:
      return 'Preview'

    case CompanyRoute.information:
      return 'Information'
    case CompanyRoute.properties:
      return 'Properties'
    case CompanyRoute.portfolios:
      return 'Portfolios'
    case CompanyRoute.users:
      return 'Users'
    case CompanyRoute.pricing:
      return 'Pricing'
    case CompanyRoute.recipientGroup:
      return 'Recipient groups'

    case AdminRoute.users:
      return 'Users'
    case AdminRoute.featureFlags:
      return 'Feature flags'
    case AdminRoute.jobs:
      return 'Jobs'
    case AdminRoute.jobQueues:
      return 'Job queues'
    case AdminRoute.alerts:
      return 'Alerts'
    case AdminRoute.comps:
      return 'Comps'
    case AdminRoute.firms:
      return 'Firms'
    case AdminRoute.properties:
      return 'Properties'
    case AdminRoute.metrics:
      return 'Metrics'
    case AdminRoute.models:
      return 'Models'
    case AdminRoute.recommendations:
      return 'Recommendations'
    case AdminRoute.graphql:
      return 'GraphQL'
    default:
      throw exhaustiveSwitchError(route)
  }
}
