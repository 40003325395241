import Pusher from 'pusher-js'
import { useEffect } from 'react'

import { lazy } from '@common/util/functions'

Pusher.logToConsole = false

const pusher = lazy(
  () =>
    new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY!, {
      cluster: 'us3',
    })
)

export function usePusherSubscribe<T>(
  PK: string,
  id: string | null | undefined,
  onPoke: (payload: T) => void
) {
  useEffect(() => {
    if (!id) {
      return
    }
    const channelName = `${PK}.${id}`
    const channel = pusher.get().subscribe(channelName)
    channel.bind('poke', (payload: T) => onPoke(payload))
    // cleanup
    return () => {
      pusher.get().unsubscribe(channelName)
    }
  }, [PK, id, onPoke])
}
