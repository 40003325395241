import { Logger } from 'tslog'

const isProd =
  process.env.NODE_ENV === 'production' || process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
const isTest = process.env.NODE_ENV === 'test'

const logger = new Logger({
  type: isTest ? 'hidden' : 'pretty',
  stylePrettyLogs: isProd ? false : true,
  // prod logs already get prefixed timestamp, remove it
  prettyLogTemplate: isProd ? '{{logLevelName}}\t[{{filePathWithLine}}{{name}}]\t' : undefined,
})

//   private isHoneyLog(args: unknown[]) {
//     return args.length >= 1 && typeof args[0] === 'string' && args[0].startsWith('honey export ')
//   }

export const log = logger
