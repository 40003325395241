import { z } from 'zod'

export const FieldError = z.object({
  field: z.string(),
  message: z.string(),
})

export type IFieldError = z.infer<typeof FieldError>

export function toFieldErrorMap(errors: IFieldError[]) {
  const errorMap: Record<string, string> = {}
  errors.forEach(({ field, message }) => {
    errorMap[field] = message
  })
  return errorMap
}

/**
 * Login
 */

export const LoginRequest = z.object({
  email: z.string().email(),
  password: z.string(),
})

export type ILoginRequest = z.infer<typeof LoginRequest>

export const LoginResponse = z.object({
  user: z.any().optional(), // TODO: bring user type into common
  password: z.string(),
})

export type ILoginResponse = z.infer<typeof LoginResponse>
