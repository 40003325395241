import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

import { shadows } from '~/theme/foundations'

const baseStyle = (props: StyleFunctionProps) => ({
  container: {
    bg: mode('white', 'gray.800')(props),
    borderRadius: 'lg',
    borderWidth: '1px',
    borderColor: mode('gray.50', 'gray.700')(props),
    shadow: mode(shadows.md, shadows['md-dark'])(props),
  },
})

export default {
  baseStyle,
}
