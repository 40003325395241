import { CheckIcon } from '@chakra-ui/icons'
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { MutableRefObject } from 'react'

import { formatNumberWithCommas, stripToNumber } from '@common/util/format'

// TODO(scott): make this more type safe in our form library
export type FormStatus = {
  submitting?: string[]
  success?: string[]
  error?: string[]
}

interface CustomInputProps extends InputProps {
  value: string
  setValue: (newValue: string) => void
  formatAs?: 'number' | 'phone' | 'verification'
  precision?: number
  status?: FormStatus
  leftElement?: any
  rightElement?: any
  rightAddon?: any
  onReturn?: (target: HTMLInputElement) => void | undefined
  onShiftReturn?: (target: HTMLInputElement) => void | undefined
  onCommandReturn?: (target: HTMLInputElement) => void | undefined
  inputRef?: MutableRefObject<HTMLInputElement>
  inputGroupWidth?: string
}

export const CustomInput = (props: CustomInputProps) => {
  const {
    value,
    setValue,
    formatAs,
    precision,
    onBlur,
    status,
    leftElement,
    rightElement,
    rightAddon,
    onReturn,
    onShiftReturn,
    onCommandReturn,
    inputRef,
    inputGroupWidth,
    ...rest
  } = props

  const format = (s: string) => {
    if (formatAs === 'phone') {
      return formatPhone(s)
    } else if (formatAs === 'number') {
      return formatNumber(s)
    } else if (formatAs === 'verification') {
      return formatVerification(s)
    }
    return s
  }

  const formatNumber = (s: string) => {
    const trimmedValue = stripToNumber(`${s}`.trim(), true)
    if (trimmedValue.length === 0) {
      return trimmedValue
    }
    const parts = trimmedValue.split('.')
    if (parts.length === 0) {
      return ''
    }
    const firstPart = formatNumberWithCommas(Number(parts[0]))
    if (parts.length === 1 || !precision || precision === 0) {
      return firstPart
    }
    const secondPart = parts[1]
    return `${firstPart}.${secondPart!.substring(0, precision)}`
  }

  const formatPhone = (s: string) => {
    let input = stripToNumber(s.trim().replace('+1', ''))
    if (input.length === 0) {
      return input
    }
    const size = input.length
    if (size > 0) {
      input = '(' + input
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4, 11)
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9, 13)
    }
    return input
  }

  const formatVerification = (s: string) => {
    let input = stripToNumber(s.trim())
    if (input.length === 0) {
      return input
    }
    const size = input.length
    if (size > 3) {
      input = input.slice(0, 3) + '-' + input.slice(3, 6)
    }
    return input
  }

  const parse = (s: string) => {
    if (formatAs === 'phone') {
      return parsePhone(s)
    } else if (formatAs === 'number') {
      return parseNumber(s)
    } else if (formatAs === 'verification') {
      return parseVerification(s)
    }
    return s
  }

  const parseNumber = (s: string) => {
    return s.replaceAll(',', '')
  }

  const parsePhone = (s: string) => {
    return stripToNumber(s).substring(0, 10)
  }

  const parseVerification = (s: string) => {
    return stripToNumber(s).substring(0, 6)
  }

  const submitting = !!rest.name && status?.submitting?.includes(rest.name)
  const success = !!rest.name && status?.success?.includes(rest.name)
  const error = !!rest.name && status?.error?.includes(rest.name)

  return (
    <InputGroup width={inputGroupWidth}>
      {leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}
      <Input
        {...rest}
        onChange={e => {
          const newValue = parse(e.target.value)
          setValue(newValue)
        }}
        value={format(value)}
        onBlur={onBlur}
        borderColor={success ? 'success' : undefined}
        color={success ? 'success' : undefined}
        onKeyDown={e => {
          const target = e.target as HTMLInputElement
          if (e.key === 'Enter') {
            if (e.metaKey === true && onCommandReturn !== undefined) {
              e.preventDefault()
              onCommandReturn(target)
            } else if (e.shiftKey === true && onShiftReturn !== undefined) {
              e.preventDefault()
              onShiftReturn(target)
            } else if (e.shiftKey === false) {
              e.preventDefault()
              if (onReturn) {
                onReturn(target)
              } else {
                e.currentTarget.blur()
              }
            }
          }
        }}
        ref={inputRef}
        isInvalid={error}
      />
      {!rightAddon && (success || submitting || rightElement) && (
        <InputRightElement>
          {success && <CheckIcon color="success" />}
          {!success && submitting && <Spinner size="sm" />}
          {!success && !submitting && rightElement}
        </InputRightElement>
      )}
      {rightAddon && (
        <InputRightAddon>
          {success && <CheckIcon color="success" />}
          {!success && submitting && <Spinner size="sm" />}
          {!success && !submitting && rightAddon}
        </InputRightAddon>
      )}
    </InputGroup>
  )
}
